<template>
  <div class="mx-4 no-saved-tasks pt-5">
    <i class="mb-3 fa-solid fa-gear fa-4x fa-spin" aria-hidden="true"></i>
    <h1>{{ $t('503_title') }}</h1>
    <h2> {{ $t('503_message') }}</h2>
  </div>
</template>

<script>
export default {
  name: 'Error503',
  mounted () {
    if (document.getElementById('preload-vue-screen')) {
      document.getElementById('preload-vue-screen').remove()
    }
  }
}
</script>

<style scoped>
  .no-saved-tasks {
    color: lightslategray;
  }
</style>
