<template>
  <div class="px-4 server-error pt-5">
    <i class="mb-3 fa-solid fa-triangle-exclamation fa-4x" aria-hidden="true"/>
    <div v-if="getNetworkOffline()">
      <h2>{{ $t('500_message_offline') }}</h2>
      <h3>{{ $t('500_message_2_offline') }}</h3>
      <h3>{{ $t('500_message_3_offline') }}</h3>
      <b-button v-if="currentRoute !== '500'" class="mt-4 w-25" variant="primary" v-on:click="refreshPage()">{{ $t('500_message_button_offline') }}</b-button>
    </div>
    <div v-else>
      <h2>{{ $t('500_message') }}</h2>
      <h3>{{ $t('500_message_2') }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error500',
  data () {
    return {
      currentRoute: ''
    }
  },
  watch: {
    $route (to, from) {
      if (to.name !== from.name) {
        this.currentRoute = to.name
      }
    }
  },
  mounted () {
    if (document.getElementById('preload-vue-screen')) {
      document.getElementById('preload-vue-screen').remove()
    }
  },
  methods: {
    getNetworkOffline () {
      return !navigator.onLine
    },
    refreshPage () {
      location.reload()
    }
  }
}
</script>

<style scoped>
.server-error {
  color: lightslategray;
  background-color: white !important;
}
</style>
